<template>
  <gmap-map
    ref="gmap"
    id="map"
    :center="center"
    :zoom="15"
    :options="options"
    map-type-id="roadmap"
  >
    <gmap-marker :position="center">
    </gmap-marker>
  </gmap-map>
</template>
<script>
  import {API_KEY} from './Maps/API_KEY'
  import Vue from 'vue'
  import * as VueGoogleMaps from 'vue2-google-maps'
  Vue.use(VueGoogleMaps, {
    load: {
      key: API_KEY
    }
  })

  let recaptchaScript = document.createElement('script')
  recaptchaScript.setAttribute('type', 'text/javascript')
  recaptchaScript.setAttribute('src', 'https://cdn.jsdelivr.net/gh/geocodezip/geoxml3@master/polys/geoxml3.js')
  document.head.appendChild(recaptchaScript)

  let ibScript = document.createElement('script')
  ibScript.setAttribute('type', 'text/javascript')
  ibScript.setAttribute('src', 'https://cdn.jsdelivr.net/gh/googlemaps/js-info-bubble@gh-pages/src/infobubble.js')
  document.head.appendChild(ibScript)

  let jqScript = document.createElement('script')
  jqScript.setAttribute('type', 'text/javascript')
  jqScript.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/1.10.1/jquery.min.js')
  document.head.appendChild(jqScript)


  export default {
    data() {
      return {
        center: {
          lat: 37.5808383,
          lng: -122.3438155
        },
        options: {
          styles: [{
            'featureType': 'water',
            'stylers': [{'saturation': 43}, {'lightness': -11}, {'hue': '#0088ff'}]
          }, {
            'featureType': 'road',
            'elementType': 'geometry.fill',
            'stylers': [{'hue': '#ff0000'}, {'saturation': -100}, {'lightness': 99}]
          }, {
            'featureType': 'road',
            'elementType': 'geometry.stroke',
            'stylers': [{'color': '#808080'}, {'lightness': 54}]
          }, {
            'featureType': 'landscape.man_made',
            'elementType': 'geometry.fill',
            'stylers': [{'color': '#ece2d9'}]
          }, {
            'featureType': 'poi.park',
            'elementType': 'geometry.fill',
            'stylers': [{'color': '#ccdca1'}]
          }, {
            'featureType': 'road',
            'elementType': 'labels.text.fill',
            'stylers': [{'color': '#767676'}]
          }, {
            'featureType': 'road',
            'elementType': 'labels.text.stroke',
            'stylers': [{'color': '#ffffff'}]
          }, {'featureType': 'poi', 'stylers': [{'visibility': 'off'}]}, {
            'featureType': 'landscape.natural',
            'elementType': 'geometry.fill',
            'stylers': [{'visibility': 'on'}, {'color': '#b8cb93'}]
          }, {'featureType': 'poi.park', 'stylers': [{'visibility': 'on'}]}, {
            'featureType': 'poi.sports_complex',
            'stylers': [{'visibility': 'on'}]
          }, {'featureType': 'poi.medical', 'stylers': [{'visibility': 'on'}]}, {
            'featureType': 'poi.business',
            'stylers': [{'visibility': 'simplified'}]
          }]
        }
      }
    },
    mounted() {
      // let mapScript = document.createElement('script')
      // mapScript.setAttribute('type', 'text/javascript')
      // mapScript.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?libraries=geometry,places&key=AIzaSyADRYcEBiV8UL-j1EXNyiaiGIl76CowJdg')
      // document.head.appendChild(mapScript)

      this.$refs.gmap.$mapPromise.then((map) => {
        // var src = 'assets/mapdata/California_County_Boundaries.kml'
        var src = 'https://drive.google.com/uc?export=download&id=1C5EOp-PxHHuliB0-GPmYmidJHatNU-ka'

        var geoXmlDoc = null;
        function useTheData(doc) {
          // Geodata handling goes here, using JSON properties of the doc object
          geoXmlDoc = doc;
          for (var i = 0; i < doc[0].placemarks.length; i++) {
            var placemark = doc[0].placemarks[i];
            polygonMouseover(placemark.polygon,placemark.COUNTY_NAME + '伟丹笨蛋');
            jQuery('#map_text').append(doc[0].placemarks[i].name + ', ');
          }
        };
        var ib = new InfoBubble({
          shadowStyle: 0,
          padding: 0,
          backgroundColor: 'white',
          borderRadius: 4,
          arrowSize: 0,
          borderWidth: 1,
          borderColor: 'black',
          disableAutoPan: true,
          hideCloseButton: true,
          arrowPosition: 50,
          arrowStyle: 0
        });
        function polygonMouseover(poly, text) {
          google.maps.event.addListener(poly,'mouseover', function(evt) {
            ib.setContent(text);
            ib.setPosition(evt.latLng);
            ib.setMap(map);
            ib.open()
          });
          google.maps.event.addListener(poly,'mouseout', function(evt) {
            ib.close()
          });
        };
        var geoXml = new geoXML3.parser({
          map: map,
          afterParse: useTheData
        });
        // geoXml.parse(src)
        geoXml.parse('California_County_Boundaries.kml');

        // map.data.loadGeoJson('https://drive.google.com/uc?export=download&id=1Y3H4rNdjjuEN5DljLodwI89TnOqWjOzK');
        // var kmlLayer = new google.maps.KmlLayer(src, {
        //         suppressInfoWindows: true,
        //         preserveViewport: false,
        //         map: map
        //       });
        // kmlLayer.addListener('hover', function(event) {
        //   var content = event.featureData.infoWindowHtml;
        //   var testimonial = document.getElementById('capture');
        //   testimonial.innerHTML = content;
        // });
      })
    }
  }
</script>
<style>
  #map {
    min-height: calc(100vh - 123px);
  }
</style>
